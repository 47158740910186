import React, { useEffect, useState } from 'react';
import { Autoplay, Navigation, Pagination, Zoom } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import Lightbox from 'react-image-lightbox';
import Home1 from '../assets/DiversHome/Home (1).jpg';
import Home2 from '../assets/DiversHome/Home (2).jpg';
import Home3 from '../assets/DiversHome/Home (3).jpg';
import Home4 from '../assets/DiversHome/Home (4).jpg';
import Home5 from '../assets/DiversHome/Home (5).jpg';
import Home6 from '../assets/DiversHome/Home (6).jpg';
import Home7 from '../assets/DiversHome/Home (7).jpg';
import Home8 from '../assets/DiversHome/Home (8).jpg';
import Home9 from '../assets/DiversHome/Home (9).jpg';

export default function SwipeCenter() {
  const [widthSwp, setwidthSwp] = useState(40);
  const [heightSwp, setheightSwp] = useState(35);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const images: string[] = [Home1, Home2, Home3, Home4, Home5, Home6, Home7, Home8, Home9];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 700) {
        setwidthSwp(30);
        setheightSwp(35);
      } else {
        if (window.innerWidth < 500) {
          setwidthSwp(15);
          setheightSwp(20);
        }else {
          setwidthSwp(20);
          setheightSwp(25);
        }

        
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const openLightbox = (index: number) => {
    setLightboxIndex(index);
    setLightboxIsOpen(true);
  };

  const closeLightbox = () => {
    setLightboxIsOpen(false);
  };

  return (
    <>
      {/* Swiper */}
      <Swiper
        slidesPerView={1}
        centeredSlides={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        zoom={true}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        className="mySwiper"
        modules={[Zoom, Autoplay, Pagination, Navigation]}
        style={{ width: `${widthSwp}rem`, height: `${heightSwp}rem`, position: 'relative' , borderRadius:"5px"}}
      >
        {images.map((imageUrl, index) => (
          <SwiperSlide key={index} onClick={() => openLightbox(index)}>
            <img src={imageUrl} alt={`Image ${index + 1}`} />
          </SwiperSlide>
        ))}
      </Swiper>

      {lightboxIsOpen && (
        <Lightbox
          mainSrc={images[lightboxIndex]}
          nextSrc={images[(lightboxIndex + 1) % images.length]}
          prevSrc={images[(lightboxIndex + images.length - 1) % images.length]}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() => setLightboxIndex((lightboxIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setLightboxIndex((lightboxIndex + 1) % images.length)}
        />
      )}
    </>
  );
}
