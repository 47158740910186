import React from 'react';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import instQr from '../assets/insta_code_QR.png'
import faceQr from '../assets/facebook_code_QR.png'

const ReseauxSociaux = () => {
  return (
    <div style={{maxWidth: '400px', margin: 'auto', backgroundColor: 'white', padding: '20px', borderRadius: '10px' }}>
      <Typography sx={{ fontSize: "30px", fontFamily: 'Roboto-Bold' }}>Suivez moi sur mes réseaux</Typography>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <a href='https://www.facebook.com/pepinotattoo' target="_blank" rel="noopener noreferrer">
        <div style={{ display: 'flex', alignItems: 'center', paddingBottom:"50px", paddingTop:"50px" }}>
          <FacebookIcon style={{ fontSize: '50px', color: '#1877f2' }} />
          <Typography style={{ marginLeft: '10px',fontSize: '20px' }}>pepinotattoo</Typography>
          <img style={{width: "130px", paddingLeft:"30px"}} src={faceQr}></img>
          
        </div>
        </a>

        <a href='https://www.instagram.com/pepinotattoo' target="_blank" rel="noopener noreferrer">
        <div style={{ display: 'flex', alignItems: 'center', paddingBottom:"50px" }}>
          <InstagramIcon style={{ fontSize: '50px', color: '#e1306c' }} />
          <Typography style={{ marginLeft: '10px',fontSize: '20px' }}>pepinotattoo</Typography>
          <img style={{width: "130px", paddingLeft:"30px"}} src={instQr}></img>
        </div>
        </a>

       

        {/* Ajoutez d'autres icônes et noms de réseaux sociaux si nécessaire */}
      </div>
    </div>
  );
};

export default ReseauxSociaux;
