import { Box, Typography } from "@mui/material"
import { ContactUs } from "../components/ContactForm"
import './Contact.css'
import ReseauxSociaux from "../components/SocialsMedia"
export const Contact = () => {
    return(
        <div className="ContactBody" style={{ display: 'flex', justifyContent: 'center'}}>
            <Box sx={{ flexDirection: 'column', alignItems: 'center', textAlign: 'center',margin: '0 100px', display:"flex"   }}>
                <ReseauxSociaux />
            </Box>

            <Box sx={{ flexDirection: 'column', alignItems: 'center', textAlign: 'center',margin: '0 100px'  }}>
                <ContactUs  />
            </Box>
        </div>

    )
}